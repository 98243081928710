import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Swal from 'sweetalert2'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
import exportValue from "../../apiconfig";
import Sub_header from '../Sub_header';
import { useNavigate, useParams } from "react-router-dom";
import Left_panel from "../Left_panel";


const Kyc_detail = () => {
    let customer_id = useParams()
    const [state, setState] = React.useState({aadhar_number:""})
    const[refId , setrefId] = React.useState({ref_id:""})
    const[optstate , setOtp] = React.useState({otp:""})
    const[userstate , setUserState] = React.useState({})
    const[companystate , setcompanyState] = React.useState({})

    const [aadharNumber, setAadharNumber] = useState('');
    const [gststate , setgstState] = useState({gst_number:""})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })
    const [formData, setFormData] = React.useState({
      aadharNumber: '',
      panNumber: '',
      gstNumber: '',
      aadharFrontImage: null,
      aadharBackImage: null,
      panFrontImage: null,
      accountname: '',
      accountNumber: '',
      accountifsc: '',
      cancelchequeimage: null,
      gstImage:null,
    });
    console.log("formData == " , formData)
    const [errors, setErrors] = React.useState({});
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    // const handleFileChange = (e, type) => {
    //   setFormData({ ...formData, [type]: e.target.files[0] });
    // };
    const handleFileChange = (e, type) => {
        const file = e.target.files[0];
        
        // Check if the uploaded file is a PDF
        if (file && file.type === 'application/pdf') {
          setFormData({ ...formData, [type]: file });
        } else if (file && file.type.startsWith('image/')) {
          setFormData({ ...formData, [type]: file });
        } else {
          // Handle invalid file types
          alert("Please upload a valid image or PDF.");
        }
      };
      const renderFilePreview = (file) => {
        let fileUrl;
      
        // Check if file is a string (URL) or a File object
        if (typeof file === "string") {
          fileUrl = file; // If it's a URL, use it directly
        } else if (file instanceof File) {
          fileUrl = URL.createObjectURL(file); // If it's a File object, create a URL
        } else {
          console.warn('Invalid file type:', file);
          return null; // Return null if the file type is invalid
        }
      
        const fileExtension = fileUrl.split('.').pop().toLowerCase(); // Get file extension from URL
      
        // Check the file extension and render accordingly
        if (["jpg", "jpeg", "png"].includes(fileExtension)) {
          // Display image if the file is an image
          return <img src={fileUrl} alt="preview" style={{ width: "100px", height: "100px" }} />;
        } else if (fileExtension === "pdf") {
          // Display a PDF icon with a link to open in a new tab
          return (
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {/* <img src={fileUrl} alt="PDF" style={{ width: "50px", height: "50px" }} /> */}
              <i class="fa fa-file-pdf-o" style={{fontSize:"48px",color:"red"}}></i>
            </a>
          );
        } else {
          // Handle unsupported file types
          console.warn('Unsupported file type or fileType is undefined:', fileExtension);
          return null;
        }
      };
   
    const handleSubmit = async (e) => {
      e.preventDefault();
  let errors = {};
  let isValid = true;

  if (!formData.aadharNumber) {
    errors.aadharNumber = 'Aadhar Number is required';
    isValid = false;
  }
  else if (formData.aadharNumber.length !== 12) {
      errors.aadharNumber = 'Aadhar Number must be exactly 12 digits';
      isValid = false;
  }

  if (!formData.panNumber) {
    errors.panNumber = 'PAN Number is required';
    isValid = false;
  }
  else if (formData.panNumber.length > 10) {
      errors.panNumber = 'PAN Number must not exceed 10 digits';
      isValid = false;
  }


  if (formData.gstNumber && formData.gstNumber.length > 15) {
      errors.gstNumber = 'GST Number must not exceed 15 digits';
      isValid = false;
  }

  if (!formData.aadharFrontImage) {
    errors.aadharFrontImage = 'Front side of Aadhar card is required';
    isValid = false;
  }

  if (!formData.aadharBackImage) {
    errors.aadharBackImage = 'Back side of Aadhar card is required';
    isValid = false;
  }

  if (!formData.panFrontImage) {
    errors.panFrontImage = 'Front side of PAN card is required';
    isValid = false;
  }
  if (!formData.accountname) {
    errors.accountname = 'Account Name is required';
    isValid = false;
  }

  if (!formData.accountNumber) {
    errors.accountNumber = 'Account Number is required';
    isValid = false;
  }

  if (!formData.accountifsc) {
    errors.accountifsc = 'IFSC Code is required';
    isValid = false;
  }

  if (!formData.cancelchequeimage) {
    errors.cancelchequeimage = 'Cancel Cheque Image is required';
    isValid = false;
  }

  

  if (!isValid) {
    setErrors(errors);
    return;
  }
      const data = new FormData();
      
      data.append('customer_id', customer_id.customer_id);

      data.append('aadharNumber', formData.aadharNumber);
      data.append('panNumber', formData.panNumber);
      data.append('gstNumber', formData.gstNumber);
      data.append('aadharFrontImage', formData.aadharFrontImage);
      data.append('aadharBackImage', formData.aadharBackImage);
      data.append('panFrontImage', formData.panFrontImage);
      data.append('gstImage', formData.gstImage);

      data.append('accountname', formData.accountname);
      data.append('accountNumber', formData.accountNumber);
      data.append('accountifsc', formData.accountifsc);
      data.append('cancelchequeimage', formData.cancelchequeimage);

    
      for (let [key, value] of data.entries()) {
          console.log(key, value);
        }

      try {
         // console.log("datatoSend",dataToSend)
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/save_customer_proof`;
   
      axios.post(full_api, data, { headers: exportValue.headers }).then((res) => {
              if(res){
                  Toast.fire({
                      background:"#206bc4",
                      type: 'success',
                      title: res.data.message ,
                      color:"white"
                    });
                    user()
                    // navigate('/customer/bank_details')
                    
               }
               else{
                  Toast.fire({
                      background:"#206bc4",
                      type: 'success',
                      title: "Something Went Wrong" ,
                      color:"white"
                    });
                  }
          })
          // Handle success or redirect user
        } catch (error) {
          console.error('Error:', error);
          // Handle error
        }
    }
    
     React.useEffect(()=>{
        user()
     },[])
    const user=()=>{
        let dataToSend = {
            customer_id:customer_id
    };
   
      // console.log("datatoSend",dataToSend)
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/find_customer_detail`;
   
        axios.post(full_api, dataToSend, { headers: exportValue.headers }).then((res) => {
        console.log("res ===" , res.data.output)
        setUserState(res.data.output)
        setcompanyState(res.data.company)
       
        setFormData({...formData ,aadharNumber:res.data.output.aadhaar_number ,panNumber:res.data.output.pan_number , gstNumber:res.data.output.gst_number , aadharFrontImage:res.data.output.aadharFrontImage , aadharBackImage:res.data.output.aadharBackImage , panFrontImage:res.data.output.panFrontImage , gstImage:res.data.output.gstImage 
            ,accountname:res.data.customer_bank?res.data.customer_bank.account_name:"" ,accountNumber:res.data.customer_bank?res.data.customer_bank.bankAccount:"" , accountifsc:res.data.customer_bank?res.data.customer_bank.ifsc:"" , cancelchequeimage:res.data.customer_bank?res.data.customer_bank.cancelchequeimage:"" })
       
          })
          .catch((error) => {
              console.log(error);
          });    
   }

   const verifyKyc = (user)=>{
   
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_skip_kyc`;
    let sendData= {customer_id:customer_id.customer_id}
    console.log("send_data === " , sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        if(res.data.status==true){
            Toast.fire({
                background: "#206bc4",
                type: 'error',
                title: "KYC Verified for this Customer !",
                color: "white"
            });
        }
        else{
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Something Went Wrong !",
                color: "white"
            });
        }
       
    }).catch((e) => {
        // setIsLoading(false)
        Toast.fire({
            background: "#e63900",
            type: 'error',
            title: "Something Went Wrong",
            color: "white"
        });
    
    });
}
  return (
    <div>
        <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <section class="home-section">
     
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
       <section class="">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div class="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/pending_kyc">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">KYC</li>
                        </ol>
                    </nav>
                </div>
            </div>
    </section>

    <section>

        <h4> {userstate.full_name}</h4>
        <div class="row">
            <div class="col-12 mb-3">
                <div class="card">
                  <div class="card-body">
                    <h5>CUSTOMER INFORMATION</h5>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Customer ID</small>
                        </div>
                        <div class="col-12 col-md">
                            {userstate.customer_id}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Name</small>
                        </div>
                        <div class="col-12 col-md">
                           {userstate.full_name}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Company Name</small>
                        </div>
                        <div class="col-12 col-md">
                            {userstate.company_name?userstate.company_name:""}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Address</small>
                        </div>
                        <div class="col-12 col-md">
                        {userstate.address?userstate.address:""}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>City</small>
                        </div>
                        <div class="col-12 col-md">
                        {userstate.city_name?userstate.city_name:""}

                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>State</small>
                        </div>
                        <div class="col-12 col-md">
                        {userstate.state_name?userstate.state_name:""}
                           
                        </div>
                    </div>
                    
                  </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                  <div class="card-body">
                    <h5>KYC INFORMATION</h5>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Aadhar No</small>
                        </div>
                        <div class="col-12 col-md">
                            <input type="text" class="form-control" id="text" name="aadharNumber"
                        value={formData.aadharNumber}
                        onChange={handleChange}/>
                          {errors.aadharNumber && <div className="text-danger mt-2">{errors.aadharNumber}</div>}
                          </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Aadhar Image</small>
                        </div>
                        <div class="col-12 col-md">
                            <div  class="text-center">
                                <small>FRONT</small>
                            </div>
                            {/* <img src={formData.aadharFrontImage} class="img-fluid mb-3 img-thumbnail" /> */}
                            {renderFilePreview(formData.aadharFrontImage)}
                            <input  class="form-control" id="text"  type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => handleFileChange(e, 'aadharFrontImage')}
                          />
                           {errors.aadharFrontImage && <div className="text-danger mt-2">{errors.aadharFrontImage}</div>}
                        </div>

                        <div class="col-12 col-md">
                            <div  class="text-center">
                                <small>BACK</small>
                            </div>
                            {/* <img src={formData.aadharBackImage} class="img-fluid mb-3 img-thumbnail" /> */}
                            {renderFilePreview(formData.aadharBackImage)}
                            <input type="file" className='form-control'
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => handleFileChange(e, 'aadharBackImage')}
                          />
                           {errors.aadharBackImage && <div className="text-danger mt-2">{errors.aadharBackImage}</div>}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>PAN No</small>
                        </div>
                        <div class="col-12 col-md">
                            <input type="text" class="form-control" id="text"  name="panNumber"
                        value={formData.panNumber}
                        onChange={handleChange}/>
                          {errors.panNumber && <div className="text-danger mt-2">{errors.panNumber}</div>}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>PAN Image</small>
                        </div>
                        <div class="col-12 col-md">
                            {/* <img src={formData.panFrontImage} class="img-fluid mb-3 img-thumbnail" /> */}
                            {renderFilePreview(formData.panFrontImage)}
                            <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => handleFileChange(e, 'panFrontImage')}
                          />
                        {errors.panFrontImage && <div className="text-danger mt-2">{errors.panFrontImage}</div>}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>GST No</small>
                        </div>
                        <div class="col-12 col-md">
                            <input type="text" class="form-control" id="text"     name="gstNumber"
                        value={formData.gstNumber}
                        onChange={handleChange}/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>GST Image</small>
                        </div>
                        <div class="col-12 col-md">
                            {/* <img src={formData.gstImage} class="img-fluid mb-3 img-thumbnail" /> */}
                            {renderFilePreview(formData.gstImage)}

                            <input className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => handleFileChange(e, 'gstImage')}
                          />
                        {errors.panFrontImage && <div className="text-danger mt-2">{errors.gstImage}</div>}
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card">
                  <div class="card-body">
                    
                    <h5>BANK INFORMATION</h5>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Bank Holder Name</small>
                        </div>
                        <div class="col-12 col-md">
                            <input type="text" class="form-control" id="text" name="accountname"
                        value={formData.accountname}
                        onChange={handleChange}/>
                           {errors.accountname && <div className="text-danger mt-2">{errors.accountname}</div>}
                        </div>
                    </div>
                  
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Account No</small>
                        </div>
                        <div class="col-12 col-md">
                            <input type="text" class="form-control" id="text" name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}/>
                          {errors.accountNumber && <div className="text-danger mt-2">{errors.accountNumber}</div>}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>IFSC CODE</small>
                        </div>
                        <div class="col-12 col-md">
                            <input type="text" class="form-control" id="text" name="accountifsc"
                        value={formData.accountifsc}
                        onChange={handleChange}/>
                           {errors.accountifsc && <div className="text-danger mt-2">{errors.accountifsc}</div>}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-3">
                            <small>Cancelled Cheque Image</small>
                        </div>
                        <div class="col-12 col-md">
                            {/* <img src={formData.cancelchequeimage} class="img-fluid mb-3 img-thumbnail" /> */}
                            {renderFilePreview(formData.cancelchequeimage)}

                            <input
                                className='form-control'
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            onChange={(e) => handleFileChange(e, 'cancelchequeimage')}/>
                             {errors.cancelchequeimage && <div className="text-danger mt-2">{errors.cancelchequeimage}</div>}
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>

        <div class="mt-3 mb-3">
            <div class="row mb-3">
                <div class="col-12 col-md pb-3">
                    <a onClick={(e)=>handleSubmit(e)} class="btn btn-outline-primary me-2">Update KYC Information</a>
                    <a onClick={(e)=>verifyKyc(e)} class="btn btn-primary ">Verfiy KYC</a>
                </div>
            </div>
        </div>
       
    </section>
    </section>
    </div>
   
  )
}

export default Kyc_detail
