import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar, ModalFooter } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import '../../components/loader.css';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const Payouts_trans_list = () => {
    const payout_id = useParams()
    console.log("payout_id == " , payout_id)
    let navigate = useNavigate()
    const [state, setState] = useState({ isLoading: true, user_list: [] , totalCompleted:0 , totalRecords:0 , create_date:"" , settle_date:"" ,status:0});
    const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 10, page: 0, total_count: 0, onload: true });
    const [isLoading, setIsLoading] = useState(false);
    const [paidloader, setpaidloader] = useState(false);
    const[paidstate , setpaidstate] = useState({utr_id:"" , paid_date:""})
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        getUsersList(0,true)
    },[])
    const getUsersList = (index = 0, onLoad) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/payout_transactions_list`;
        let sendData = { payout_id:payout_id.payout_id,dstatus: otherStates.dstatus, limit: otherStates.rowsPerPage, indexValue: index };
    
        // if (searchfilters.searchtitle) {
        //     sendData.searchKeyword = searchfilters.searchtitle
        // }
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            setIsLoading(false)
            console.log("response ", res);
            console.log("otherStates ", otherStates);
            // if (index == 0 && onLoad) {
            //     setOtherState({ ...otherStates, total_count: res.data.output.length })
    
            // }
            let create_date = res.data.output[0].create_date
            let settle_date= res.data.output[0].settle_date
            let status= res.data.output[0].status

            let transactions = res.data.output[0].transactions
            let totalRecords = transactions.length;

            // Calculate total number of completed transactions
            let totalCompleted = transactions.reduce((count, transaction) => {
              return count + (transaction.status === 1 ? 1 : 0);
            }, 0);
            setState({ ...state, user_list: res.data.output,totalRecords:totalRecords,totalCompleted:totalCompleted,create_date:create_date,settle_date:settle_date,status:status, isLoading: false })
           
    
    
    
        }).catch((e) => {
            setIsLoading(false)
    
        });
    }

    const handleChange = (e)=>{
        setpaidstate({...paidstate,[e.target.name]:e.target.value})
    }
    const markPaid =(item)=>{
        setpaidloader(true)
     if(paidstate.utr_id!=""){
      if(paidstate.paid_date!=""){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/mark_as_paid_payout`;
        let sendData = { payout_id:payout_id.payout_id,transaction_id:item.transaction_id ,utr_id:paidstate.utr_id , paid_date:paidstate.paid_date  };
    
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            if(res.data.status==true){
                Swal.fire({
                    icon: 'success',
                    title: 'Great...',
                    text:  "Mark As Paid",
                    
                  })
            setpaidloader(false)
            getUsersList(0,true)
           
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Something Went Wrong",
                    
                  })
                  setpaidloader(false)
                  getUsersList(0,true)
            }
          
        }).catch((e) => {
            setpaidloader(false)
    
        });
      }
      else{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please Fill Paid Date!',
            
          })
          setpaidloader(false)
      }
     }
     else{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please Fill UTR ID!',
            
          })
          setpaidloader(false)
     }
    }

    const csvinputEditHandleChange = (e) => {        
        if (e.target.type == "file") {            
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        }
         else {
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        }
      }
      const uploadPincodeFile= () => {
     
        if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
           // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
            let fileName = uploadModalState.excel_fle[0].name;
           // console.log("fileName =======>                 ",fileName);
           
           // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
           // return 
            if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_payout_file`;
         let bodyFormData = new FormData()
      
         bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
         bodyFormData.append("payout_id", payout_id.payout_id);

         
     
        console.log("bb", bodyFormData);
       
  
        axios.post(full_api, bodyFormData, {
            headers:exportValue.headers
        }).then((res) => {
            console.log("res ", res);
  
         
          setUploadModalState({...uploadModalState, show: false });
           
            if (res.status == 200 && res.data.status == "success") {
               const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
            })
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Bulk Import Process Starts",
                    color: "white"
                });
                setTimeout(()=>{
                //   window.location.reload();
                    // navigate(`/payout_import_log/${payout_id.payout_id}`)
                },1000)
                
            }
            
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
  
                })
            }
        }).catch((e) => {
  
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
  
            })
        });
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
        Toast.fire({
            background: "#8a2be2",
            type: 'error',
            title: "Please choose CSV file!",
            color: "white"
        });
    }
    }else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    })
             Toast.fire({
                    background: "#10b93b",
                    type: 'error',
                    title: "Please choose a file!",
                    color: "white"
                });
    }
  }

  const downloadPayoutSummary = (data) => {
    // Prepare the data
    const payoutData = data.map(payout => {
      return payout.transactions.map(transaction => ({
        TRANSACTION: transaction.transaction_id,
        'WALLET ADJ': transaction.wallet_adjusted_amount,
        PAYOUT: transaction.payout_amount,
        'CUSTOMER NAME': transaction.customer_name,
        'BANK NAME': transaction.acc_name,
        'BANK ACCOUNT NUMBER': transaction.acc_no,
        'IFSC CODE': transaction.ifsc_code,
        STATUS: transaction.status==1?"Completed":"Under Process",
        UTR: transaction.utr_id?transaction.utr_id:"",
        PAID_DATE: transaction.paid_date?transaction.paid_date:"",
      }));
    }).flat(); // Flatten the array of arrays
  
    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(payoutData);
  
    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Payout Summary');
  
    // Convert the workbook to a binary array
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
    // Save the file
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'payout_summary.xlsx');
  };
  return (
    <div>
     <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/> 
 
<section class="mt-3">

<div class="row my-3">
                    <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
                        <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li class="breadcrumb-item">
                                <a href="/payout">
                                    <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Payouts</li>
                        </ol>
                    </nav>
        <div class="col-12 col-md">
            <div class="d-flex">
                <div class="">
                    <h2><small>Payout #</small>{payout_id.payout_id}</h2>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-5 text-end">
            <small>Completed: <strong>{state.totalCompleted}</strong>/{state.totalRecords}</small>  
        <small className="ms-2">Created Date:  <Moment format="MMM DD YYYY">
                        {new Date(state.create_date*1000)}
             </Moment>   Settle Date: {state.settle_date} </small>
        </div>
        <div class="col-12 col-md-2 text-end shipm_tablew">
          {state.status==0?  <span class="badge bg-warning"> <i class="fa fa-clock-o" aria-hidden="true"></i>Under Processing </span>: <span class="badge bg-success"> <i class="fa fa-check" aria-hidden="true"></i> Completed</span>}
        </div>
    </div>


<div class="row mb-3">
    <div class="col-12 col-md">
        <div class="row justify-content-end">
            <div class="col-12 col-md-2">
                {/* <div class="input-group me-2 me-lg-3 fmxw-400">
                    <input type="text" class="form-control" placeholder="Search Transcation"/>
                    <span class="input-group-text ">
                        <svg class="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                </div> */}
            </div>
            <div class="col-12 col-md-2">
                {/* <input type="text" class="form-control" id="text" placeholder="Select Customer"/> */}
            </div>
            <div class="col-12 col-md-2">
                {/* <select class="form-select">
                <option>All Status</option>
                <option>Under Process</option>
                <option>Completed</option>
                </select> */}
            </div>

            <div class="col-12 col-md-3">
                <div class="d-grid">
                <a  onClick={() => downloadPayoutSummary(state.user_list)} class="btn btn-primary btn-block"><i class="bx bx-download"></i> Download Payout Summary</a>
                </div>
            </div>

            <div class="col-12 col-md-3">
                <div class="d-grid">
                <a data-bs-toggle="modal" data-bs-target="#import_pincode_special" class="btn btn-warning btn-block"  data-bs-backdrop="false"><i class="bx bx-upload"></i> Import & Update Status</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="import_pincode_special"  data-bs-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Import Payout File</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-success">
          <strong>Alert!</strong> 
          <ul>
            <li>This action can not be undone.</li>
            <li>Doing the corrupt or broken variable file can cause system malfunction.</li>
            <li>Download sample file and setup the file in correct format to import</li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="../../../assets/doc/payout.csv" target="_blank"  class="btn btn-outline-primary btn-sm">Downlaod Sample File</a></div>
        </div>
        <hr/>
     
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary"  onClick={() =>uploadPincodeFile()}>Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
    <div class="card card-body border-0 shadow table-wrapper table-responsive ">
        <table class="table table-hover  shipm_tablew">
            <thead style={{background:"black"}}>
                <tr>
                    <th style={{color:"white"}}>Transcation #</th>	
                    {/* <th style={{color:"white"}}>Total COD <a data-bs-toggle="tooltip" title="Total COD Customer Wallet Amount"><i class="bx bx-info-circle"></i></a></th>	 */}
                    <th style={{color:"white"}}>WALLET ADJ. <a data-bs-toggle="tooltip" title="Prepaid Wallet Adjusted from COD Amount"><i class="bx bx-info-circle"></i></a></th>	
                    <th style={{color:"white"}}>Payout <a data-bs-toggle="tooltip" title="Total Releasable Payout after adjustment if any"><i class="bx bx-info-circle"></i></a></th>
                    <th style={{color:"white"}}>UTR #</th>
                    <th style={{color:"white"}}>Bank Details</th>
                    <th style={{color:"white"}}>Status</th>
                    <th style={{color:"white"}}>Action</th>
                </tr>
            </thead>
            {state.user_list.map((sub)=>(
                sub.transactions.length>0?
                sub.transactions.map((item)=>(
            <tbody>
                <tr>
                    <td>
                       <a className="" href={`/payout_detail/${payout_id.payout_id}/${item.customer_id}`}>{item.transaction_id}</a> 
                    </td>    
                       
                    <td class="fw-700 text-danger">
                        (-)₹{item.wallet_adjusted_amount}<br/>
                        {/* <small><a href="#" class="text-muted">TID: 923894234345678</a></small> */}
                    </td>    
                    <td class="fw-700 text-success">
                        ₹{item.payout_amount}
                    </td>                    
                    <td>
                    <div class="mb-2">
                        {item.utr_id!=""? <>{item.utr_id}<br/>
                  </> : <input type="text" class="form-control form-control-sm" id="text" placeholder="Enter UTR No" name="utr_id" onChange={(e)=>handleChange(e)}/>}
                       
                    </div>
                    <div>
                        <small>Paid Date</small>
                        {item.paid_date!=""?<small>{item.paid_date}</small>:
                        <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter UTR No" name="paid_date" onChange={(e)=>handleChange(e)}/>}
                    </div>
                    </td>  
                    <td class="">
                    <a href={"/customers_info/"+item.customer_id}>{item.customer_name}</a>
                    <br/>
                    <small>
                    Acc no: {item.acc_no}<br/>
                    IFSC Code: {item.ifsc_code}<br/>
                   
                    </small>
                    </td>
                    <td>
                   {item.status==0?     <span class="badge bg-warning"> <i class="fa fa-clock-o" aria-hidden="true"></i> Under Process</span>: <span class="badge bg-success"> <i class="fa fa-check" aria-hidden="true"></i> Completed</span>}
                    </td>
                    <td>{item.status==0?
                     paidloader? <ReactLoading type={"spin"} color={"#000000"} height={30} width={30} />:   <a onClick={(e)=>markPaid(item)}  class="btn btn-success  btn-sm">
                            Mark as Paid
                        </a>
                        :""}
                    </td>
                </tr>
              
            </tbody>
          )):""  ))}
        </table>
        {/* <div class="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <nav aria-label="Page navigation example">
                <ul class="pagination mb-0">
                    <li class="page-item">
                        <a class="page-link" href="#">Previous</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item active">
                        <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">4</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">5</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#">Next</a>
                    </li>
                </ul>
            </nav>
            <div class="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
        </div> */}
    </div> 
        
</section>
</section>

</div>
    
  )
}

export default Payouts_trans_list
